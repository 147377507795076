import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../reportlost.component.css";
import "font-awesome/css/font-awesome.min.css";
import Button from "@material-ui/core/Button";
import { ToggleButton } from "@material-ui/lab";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import axios from "axios";
import Footer from "../../footer/footer.component.jsx";
import MapContainer from "../testMap";
import "date-fns";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
// import Userimage from "../../../assets/user2.png";
import { Link } from "react-router-dom";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Navbar from "../../header/header.component";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import { switchUrls } from "../../../api/index.js";

import { Tabs } from "@material-ui/core";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  RadioGroup,
  useRadioGroup,
  Tab,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Radio from "@material-ui/core/Radio";
import { styled } from "@material-ui/core/styles";
import MuiPhoneInput from "material-ui-phone-number";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";

const StyledFormControlLabel = styled((props) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  ".MuiFormControlLabel-label": checked && {
    color: theme.palette.primary.main,
  },
}));

function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}

const baseURL = switchUrls("genie");

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) == " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}

let usertoken = readCookie("token");
let token = usertoken;

function getSteps() {
  return ["Loss Details", "Location", "Date & Time of Loss", "Pet Details"];
}

const steps = getSteps();

export default class EditReportLost extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebar: true,
      subMenu: true,
      alignment: "left",
      tag_number: this.props.match.params.tag_number,
      lf_status: "lost",
      valuable_type: "",
      pet_age: "",
      pet_name: "",
      pet_bloodgrp: "",
      pet_breed: "",
      pet_color: "",
      pet_dob: "",
      pet_ident: "",
      veternity: "",
      pet_gender: "",
      identification_mark: "",
      parent_firstname: "",
      parent_lastname: "",
      phone_one: "",
      phone_two: "",
      phone_three: "",
      parent_email: "",
      parent_altemail: "",
      lost_comment: "",
      lost_date: "",
      reward: "",
      rewardcurrency: "",
      lost_timeone: "",
      lost_timetwo: "",
      latitude: "",
      longitude: "",
      dr_phone: "",
      pet_imagelocation: "",
      snackbaropen: false,
      editlocation: false,
      snackbarmsg: "",
      activeStep: 0,
      showLoader: true,
      editlocation: false,
    };

    // this.getCoordinates = this.getCoordinates.bind(this);
    // this.getlatestposition = this.getlatestposition.bind(this);
    this.showSidebar = this.showSidebar.bind(this);
    this.showSubMenu = this.showSubMenu.bind(this);
  }

  parseTimeStringToTimeObject(timeStr) {
    if (typeof timeStr !== "string" || timeStr.trim().length === 0) {
      console.error("Invalid time format:", timeStr);
      return null;
    }
    // console.log(timeStr, "stringtime");
    const timeFormat = "hh:mm A";

    try {
      const parsedTime = moment(timeStr, timeFormat);
      // console.log("ftime3 : ",parsedTime.toDate())
      return parsedTime.isValid() ? parsedTime.toDate() : null;
    } catch (error) {
      console.error("Invalid time format:", timeStr);
      return null;
    }
  }

  handleAlignment = (event, alignment) => this.setState({ alignment });

  componentDidMount() {
    if (navigator.geolocation) {
      // navigator.geolocation.getCoordinates();
      // navigator.geolocation.getCurrentPosition(this.getCoordinates);
    } else {
      alert("Geolocation is not supported by this browser.");
    }

    const access_token = token;
    const getURL =
      `${baseURL}/api/pet/getLostDetails/` + this.props.match.params.tag_number;
    axios
      .get(getURL, {
        headers: {
          Authorization: access_token,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("Lost Details Response: ", response);
        const lastIndexData =
          response.data.length > 0 ? response.data.length - 1 : 0;
        console.log("getLostDetails resp: ", response);
        this.setState({
          valuable_type: response.data[lastIndexData].valuable_type,
          pet_age: response.data[lastIndexData].pet_age,
          pet_name: response.data[lastIndexData].pet_name,
          pet_dob: response.data[lastIndexData].pet_dob,
          pet_ident: response.data[lastIndexData].pet_ident,
          pet_gender: response.data[lastIndexData].pet_gender,
          veternity: response.data[lastIndexData].veternity,
          parent_firstname: response.data[lastIndexData].parent_firstname,
          parent_lastname: response.data[lastIndexData].parent_lastname,
          phone_one: response.data[lastIndexData].phone_one,
          phone_two: response.data[lastIndexData].phone_two,
          phone_three: response.data[lastIndexData].phone_three,
          parent_email: response.data[lastIndexData].parent_email,
          parent_altemail: response.data[lastIndexData].parent_altemail,
          pet_bloodgrp: response.data[lastIndexData].pet_bloodgrp,
          pet_breed: response.data[lastIndexData].pet_breed,
          pet_color: response.data[lastIndexData].pet_color,
          identification_mark: response.data[lastIndexData].identification_mark,
          lost_comment: response.data[lastIndexData].lost_comment,
          lost_date: new Date(response.data[lastIndexData].lost_date),
          reward: response.data[lastIndexData].reward,
          rewardcurrency: response.data[lastIndexData].reward_currency,
          lost_timeone: response.data[lastIndexData].lost_timestart,
          lost_timetwo: response.data[lastIndexData].lost_timeend,
          latitude: response.data[lastIndexData].lost_latitude,
          longitude: response.data[lastIndexData].lost_longitude,
          dr_phone: response.data[lastIndexData].dr_phone,
        });
        setTimeout(() => {
          this.setState({ showLoader: false });
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  sendlatitude = (data) => {
    console.log("data", data);
    this.setState({
      latitude: data.lat,
      longitude: data.lng,
    });
  };
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  dateChangeHandler = (Date) => {
    console.log(Date.target.value);
    this.setState({ [Date.target.name]: Date.target.value });
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  submitHandler = (e) => {
    e.preventDefault();
    console.log(this.state);
    console.log(this.state.lost_date, "lost date");
    const tag_number = this.state.tag_number;
    axios
      .post(
        `${baseURL}/api/pet/editlostdetails/` +
          this.props.match.params.tag_number,
        this.state,
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        console.log(response);
        console.log(response.data);
        if (response.data === "Lost details updated") {
          this.setState({ snackbaropen: true, snackbarmsg: response.data });
          window.location.href = "/#/pet-poster/" + tag_number;
        } else {
          this.setState({
            snackbaropen: true,
            snackbarmsg: "Edit lost Details Failed",
          });
          window.location.href = "/dashboard/" + localStorage.getItem("uid");
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          snackbaropen: true,
          snackbarmsg: "Edit lost Details Failed",
        });
      });
  };
  getStepContent = (stepIndex) => {
    const {
      lost_comment,
      lost_date,
      latitude,
      longitude,
      lost_timeone,
      lost_timetwo,
      alignment,
      tag_number,
      valuable_type,
      parent_firstname,
      parent_lastname,
      phone_one,
      phone_two,
      parent_email,
      parent_altemail,
      reward,
      rewardcurrency,
      pet_age,
      pet_name,
      pet_breed,
      pet_gender,
      pet_color,
      pet_dob,
      pet_bloodgrp,
      pet_ident,
      veternity,
      dr_phone,
      phone_three,
      editlocation,
    } = this.state;

    console.log("state: ", this.state);

    let timeOneValue = this.state.lost_timeone;
    let timeTwoValue = this.state.lost_timetwo;

    console.log(timeOneValue, "valueone");

    if (moment(this.state.lost_timeone, moment.ISO_8601, true).isValid()) {
      timeOneValue = moment(this.state.lost_timeone).format("hh:mm A");
    }
    if (moment(this.state.lost_timetwo, moment.ISO_8601, true).isValid()) {
      timeTwoValue = moment(this.state.lost_timetwo).format("hh:mm A");
    }

    const timeOneDate = this.parseTimeStringToTimeObject(timeOneValue);
    const timeTwoDate = this.parseTimeStringToTimeObject(timeTwoValue);

    switch (stepIndex) {
      case 0:
        return (
          <div className="reportlost-loss-details">
            <textarea
              rows="4"
              className=""
              name="lost_comment"
              placeholder="Give a brief account of loss. [Eg] Were you in a cab?"
              value={lost_comment}
              onChange={this.changeHandler}
            ></textarea>

            <div className="reportlost-loss-details-btns">
              <Button
                type="button"
                onClick={() => {
                  window.location.href =
                    "/dashboard/" + localStorage.getItem("uid");
                }}
                variant="contained"
              >
                CANCEL
              </Button>

              <Button
                variant="contained"
                onClick={this.handleNext}
                style={{ backgroundColor: "#ffc107" }}
              >
                Next
              </Button>
            </div>
          </div>
        );
      case 1:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>
              <div className="row mapcontainer-row">
                <MapContainer
                  className="googlemaps"
                  sendlatitude={this.sendlatitude}
                  lostlatitude={this.state.latitude}
                  lostlongitude={this.state.longitude}
                  editlocation={this.state.editlocation}
                ></MapContainer>
              </div>
            </div>

            <div className="reportlost-location-input-cnt">
              <div className="reportlost-location-input-cnt-lat">
                <span className="">Latitude</span>

                <input
                  type="text"
                  className="reportlost-location-input"
                  value={latitude}
                  name="latitude"
                  onChange={this.changeHandler}
                  readOnly
                />
              </div>

              <div className="reportlost-location-input-cnt-lon">
                <span className="">Longitude</span>

                <input
                  type="text"
                  className="reportlost-location-input"
                  value={longitude}
                  name="longitude"
                  onChange={this.changeHandler}
                  readOnly
                />
              </div>
            </div>

            <div className="reportlost-location-btns">
              <Button variant="contained" onClick={this.handleBack}>
                Back
              </Button>

              <Button
                variant="contained"
                value="right"
                onClick={this.handleNext}
                style={{ backgroundColor: "#ffc107" }}
              >
                Next
              </Button>

              <Button
                type="button"
                onClick={() => {
                  window.location.href =
                    "/dashboard/" + localStorage.getItem("uid");
                }}
                variant="contained"
              >
                CANCEL
              </Button>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="date-time-loss">
            <div className="lost-div" style={{ width: "50%" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Lost Date"
                  onChange={(date) => this.setState({ lost_date: date })}
                  defaultValue={lost_date}
                  disableFuture
                />
              </LocalizationProvider>
            </div>
            <div className="lost-div">
              <div className="lost-textfield">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    label="From Time"
                    onChange={(timeValue) => {
                      const formattedTime = moment(timeValue).format("hh:mm A");
                      this.setState({ lost_timeone: formattedTime });
                    }}
                    value={timeOneDate}
                  />
                </LocalizationProvider>
              </div>
              <div className="lost-textfield">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    label="To Time"
                    onChange={(timeValue) => {
                      const formattedTime = moment(timeValue).format("hh:mm A");
                      this.setState({ lost_timetwo: formattedTime });
                    }}
                    value={timeTwoDate}
                    timezone="system"
                  />
                </LocalizationProvider>
              </div>
            </div>

            <div className="date-time-loss-btns">
              <Button variant="contained" onClick={this.handleBack}>
                Back
              </Button>

              <Button
                variant="contained"
                onClick={this.handleNext}
                style={{ backgroundColor: "#ffc107" }}
              >
                Next
              </Button>

              <Button
                type="button"
                onClick={() => {
                  window.location.href =
                    "/dashboard/" + localStorage.getItem("uid");
                }}
                variant="contained"
              >
                CANCEL
              </Button>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="reportlost-container">
            <div className="reportlost-container-pet-details-span">
              <h4>Pet Details</h4>

              {/* tag number and valuable type */}
              <div className="reportlost-one-line">
                <div className="reportlost-input">
                  <TextField
                    variant="outlined"
                    label="Tag Number"
                    value={
                      tag_number && tag_number !== "-" ? tag_number : "N/A"
                    }
                    required
                    inputProps={{
                      readOnly: true,
                      startAdornment: (
                        <img
                          src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                          style={{ width: "30px", height: "30px" }}
                        ></img>
                      ),
                    }}
                  />
                </div>
                <div className="reportlost-input">
                  <TextField
                    variant="outlined"
                    label="Pet Type"
                    value={valuable_type}
                    required
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </div>
              </div>

              {/* name and breed */}
              <div className="reportlost-one-line">
                <div className="reportlost-input">
                  <TextField
                    type="text"
                    variant="outlined"
                    label="Pet Name "
                    name="pet_name"
                    value={pet_name}
                    onChange={this.changeHandler}
                    required
                    inputProps={{
                      max: 5,
                      min: 1,
                    }}
                  />
                </div>
                <div className="reportlost-input">
                  {!valuable_type ? (
                    <TextField
                      variant="outlined"
                      select
                      label="Pet Breed *"
                      value={pet_breed}
                      onChange={this.changeHandler}
                      required
                    >
                      <MenuItem defaultValue disabled hidden></MenuItem>
                    </TextField>
                  ) : (
                    <div></div>
                  )}

                  {valuable_type === "Dog" ? (
                    <>
                      <TextField
                        select
                        label="Pet Breed"
                        name="pet_breed"
                        value={pet_breed}
                        onChange={this.changeHandler}
                        variant="outlined"
                      >
                        <MenuItem hidden></MenuItem>
                        <MenuItem value="Affenpinscher">Affenpinscher</MenuItem>
                        <MenuItem value="Afghan Hound">Afghan Hound</MenuItem>
                        <MenuItem value="Airedale Terrier">
                          Airedale Terrier
                        </MenuItem>
                        <MenuItem value="Akita">Akita</MenuItem>
                        <MenuItem value="Alaskan Malamute">
                          Alaskan Malamute
                        </MenuItem>
                        <MenuItem value="AmericanPitbull">
                          American Pit bull
                        </MenuItem>
                        <MenuItem value="American Staffordshire Terrier">
                          American Staffordshire Terrier
                        </MenuItem>
                        <MenuItem value="American Water Spaniel">
                          American Water Spaniel
                        </MenuItem>
                        <MenuItem value="Australian Cattle Dog">
                          Australian Cattle Dog
                        </MenuItem>
                        <MenuItem value="Australian Shepherd">
                          Australian Shepherd
                        </MenuItem>
                        <MenuItem value="Australian Terrier">
                          Australian Terrier
                        </MenuItem>
                        <MenuItem value="Basenji">Basenji</MenuItem>
                        <MenuItem value="Basset Hound">Basset Hound</MenuItem>
                        <MenuItem value="Beagle">Beagle</MenuItem>
                        <MenuItem value="Bearded Collie">
                          Bearded Collie
                        </MenuItem>
                        <MenuItem value="Bedlington Terrier">
                          Bedlington Terrier
                        </MenuItem>
                        <MenuItem value="Bernese Mountain Dog">
                          Bernese Mountain Dog
                        </MenuItem>
                        <MenuItem value="Bichon Frise">Bichon Frise</MenuItem>
                        <MenuItem value="Black And Tan Coonhound">
                          Black And Tan Coonhound
                        </MenuItem>
                        <MenuItem value="Bloodhound">Bloodhound</MenuItem>
                        <MenuItem value="Border Collie">Border Collie</MenuItem>
                        <MenuItem value="Border Terrier">
                          Border Terrier
                        </MenuItem>
                        <MenuItem value="Borzoi">Borzoi</MenuItem>
                        <MenuItem value="Boston Terrier">
                          Boston Terrier
                        </MenuItem>
                        <MenuItem value="Bouvier Des Flandres">
                          Bouvier Des Flandres
                        </MenuItem>
                        <MenuItem value="Boxer">Boxer</MenuItem>
                        <MenuItem value="Briard">Briard</MenuItem>
                        <MenuItem value="Brittany">Brittany</MenuItem>
                        <MenuItem value="Brussels Griffon">
                          Brussels Griffon
                        </MenuItem>
                        <MenuItem value="Bull Terrier">Bull Terrier</MenuItem>
                        <MenuItem value="Bulldog">Bulldog</MenuItem>
                        <MenuItem value="Bullmastiff">Bullmastiff</MenuItem>
                        <MenuItem value="Cairn Terrier">Cairn Terrier</MenuItem>
                        <MenuItem value="Canaan Dog">Canaan Dog</MenuItem>
                        <MenuItem value="Chesapeake Bay Retriever">
                          Chesapeake Bay Retriever
                        </MenuItem>
                        <MenuItem value="Chihuahua">Chihuahua</MenuItem>
                        <MenuItem value="Chinese Crested">
                          Chinese Crested
                        </MenuItem>
                        <MenuItem value="Chinese Shar-Pei">
                          Chinese Shar-Pei
                        </MenuItem>
                        <MenuItem value="Chow Chow">Chow Chow</MenuItem>
                        <MenuItem value="Clumber Spaniel">
                          Clumber Spaniel
                        </MenuItem>
                        <MenuItem value="Cocker Spaniel">
                          Cocker Spaniel
                        </MenuItem>
                        <MenuItem value="Collie">Collie</MenuItem>
                        <MenuItem value="Cross Breed">Cross Breed</MenuItem>
                        <MenuItem value="Curly-Coated Retriever">
                          Curly-Coated Retriever
                        </MenuItem>
                        <MenuItem value="Dachshund">Dachshund</MenuItem>
                        <MenuItem value="Dalmatian">Dalmatian</MenuItem>
                        <MenuItem value="Doberman Pinscher">
                          Doberman Pinscher
                        </MenuItem>
                        <MenuItem value="English Cocker Spaniel">
                          English Cocker Spaniel
                        </MenuItem>
                        <MenuItem value="English Setter">
                          English Setter
                        </MenuItem>
                        <MenuItem value="English Springer Spaniel">
                          English Springer Spaniel
                        </MenuItem>
                        <MenuItem value="English Toy Spaniel">
                          English Toy Spaniel
                        </MenuItem>
                        <MenuItem value="Eskimo Dog">Eskimo Dog</MenuItem>
                        <MenuItem value="Finnish Spitz">Finnish Spitz</MenuItem>
                        <MenuItem value="Flat-Coated Retriever">
                          Flat-Coated Retriever
                        </MenuItem>
                        <MenuItem value="Fox Terrier">Fox Terrier</MenuItem>
                        <MenuItem value="Foxhound">Foxhound</MenuItem>
                        <MenuItem value="French Bulldog">
                          French Bulldog
                        </MenuItem>
                        <MenuItem value="German Shepherd">
                          German Shepherd
                        </MenuItem>
                        <MenuItem value="German Shorthaired Pointer">
                          German Shorthaired Pointer
                        </MenuItem>
                        <MenuItem value="German Wirehaired Pointer">
                          German Wirehaired Pointer
                        </MenuItem>
                        <MenuItem value="Golden Retriever">
                          Golden Retriever
                        </MenuItem>
                        <MenuItem value="Gordon Setter">Gordon Setter</MenuItem>
                        <MenuItem value="Great Dane">Great Dane</MenuItem>
                        <MenuItem value="Greyhound">Greyhound</MenuItem>
                        <MenuItem value="Indian Paraigh Dog">
                          Indian Paraigh Dog
                        </MenuItem>
                        <MenuItem value="Irish Setter">Irish Setter</MenuItem>
                        <MenuItem value="Irish Water Spaniel">
                          Irish Water Spaniel
                        </MenuItem>
                        <MenuItem value="Irish Wolfhound">
                          Irish Wolfhound
                        </MenuItem>
                        <MenuItem value="Jack Russell Terrier">
                          Jack Russell Terrier
                        </MenuItem>
                        <MenuItem value="Japanese Spaniel">
                          Japanese Spaniel
                        </MenuItem>
                        <MenuItem value="Keeshond">Keeshond</MenuItem>
                        <MenuItem value="Kerry Blue Terrier">
                          Kerry Blue Terrier
                        </MenuItem>
                        <MenuItem value="Komondor">Komondor</MenuItem>
                        <MenuItem value="Kuvasz">Kuvasz</MenuItem>
                        <MenuItem value="Labrador">Labrador</MenuItem>
                        <MenuItem value="Labrador Retriever">
                          Labrador Retriever
                        </MenuItem>
                        <MenuItem value="Lakeland Terrier">
                          Lakeland Terrier
                        </MenuItem>
                        <MenuItem value="Lhasa Apso">Lhasa Apso</MenuItem>
                        <MenuItem value="Maltese">Maltese</MenuItem>
                        <MenuItem value="Manchester Terrier">
                          Manchester Terrier
                        </MenuItem>
                        <MenuItem value="Mastiff">Mastiff</MenuItem>
                        <MenuItem value="Mexican Hairless">
                          Mexican Hairless
                        </MenuItem>
                        <MenuItem value="Mixed Breed">Mixed Breed</MenuItem>
                        <MenuItem value="Mongreal">Mongreal</MenuItem>
                        <MenuItem value="Mudhol">Mudhol</MenuItem>
                        <MenuItem value="Newfoundland">Newfoundland</MenuItem>
                        <MenuItem value="Norwegian Elkhound">
                          Norwegian Elkhound
                        </MenuItem>
                        <MenuItem value="Norwich Terrier">
                          Norwich Terrier
                        </MenuItem>
                        <MenuItem value="Otterhound">Otterhound</MenuItem>
                        <MenuItem value="Papillon">Papillon</MenuItem>
                        <MenuItem value="Pekingese">Pekingese</MenuItem>
                        <MenuItem value="Pointer">Pointer</MenuItem>
                        <MenuItem value="Poodle">Poodle</MenuItem>
                        <MenuItem value="Pomeranian">Pomeranian</MenuItem>
                        <MenuItem value="Pug">Pug</MenuItem>
                        <MenuItem value="Puli">Puli</MenuItem>
                        <MenuItem value="Rhodesian Ridgeback">
                          Rhodesian Ridgeback
                        </MenuItem>
                        <MenuItem value="Rottweiler">Rottweiler</MenuItem>
                        <MenuItem value="Saint Bernard">Saint Bernard</MenuItem>
                        <MenuItem value="Saluki">Saluki</MenuItem>
                        <MenuItem value="Samoyed">Samoyed</MenuItem>
                        <MenuItem value="Schipperke">Schipperke</MenuItem>
                        <MenuItem value="Schnauzer">Schnauzer</MenuItem>
                        <MenuItem value="Scottish Deerhound">
                          Scottish Deerhound
                        </MenuItem>
                        <MenuItem value="Scottish Terrier">
                          Scottish Terrier
                        </MenuItem>
                        <MenuItem value="Sealyham Terrier">
                          Sealyham Terrier
                        </MenuItem>
                        <MenuItem value="Shetland Sheepdog">
                          Shetland Sheepdog
                        </MenuItem>
                        <MenuItem value="Shihtzu">Shihtzu</MenuItem>
                        <MenuItem value="Siberian Husky">
                          Siberian Husky
                        </MenuItem>
                        <MenuItem value="Silky Terrier">Silky Terrier</MenuItem>
                        <MenuItem value="Skye Terrier">Skye Terrier</MenuItem>
                        <MenuItem value="Staffordshire Bull Terrier">
                          Staffordshire Bull Terrier
                        </MenuItem>
                        <MenuItem value="Soft-Coated Wheaten Terrier">
                          Soft-Coated Wheaten Terrier
                        </MenuItem>
                        <MenuItem value="Sussex Spaniel">
                          Sussex Spaniel
                        </MenuItem>
                        <MenuItem value="Spitz">Spitz</MenuItem>
                        <MenuItem value="Tibetan Terrier">
                          Tibetan Terrier
                        </MenuItem>
                        <MenuItem value="Vizsla">Vizsla</MenuItem>
                        <MenuItem value="Weimaraner">Weimaraner</MenuItem>
                        <MenuItem value="Welsh Terrier">Welsh Terrier</MenuItem>
                        <MenuItem value="West Highland White Terrier">
                          West Highland White Terrier
                        </MenuItem>
                        <MenuItem value="Whippet">Whippet</MenuItem>
                        <MenuItem value="Yorkshire Terrier">
                          Yorkshire Terrier
                        </MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </TextField>
                    </>
                  ) : (
                    <div></div>
                  )}

                  {valuable_type === "Cat" ? (
                    <>
                      <TextField
                        // className="editPetDetails-select-tag"
                        select
                        label="Pet Breed"
                        name="pet_breed"
                        value={pet_breed}
                        onChange={this.changeHandler}
                        variant="outlined"
                      >
                        <MenuItem hidden></MenuItem>
                        <MenuItem value="Abyssinian">Abyssinian</MenuItem>
                        <MenuItem value="American Bobtail">
                          American Bobtail
                        </MenuItem>
                        <MenuItem value="American Curl">American Curl</MenuItem>
                        <MenuItem value="American Shorthair">
                          American Shorthair
                        </MenuItem>
                        <MenuItem value="American Wirehair">
                          American Wirehair
                        </MenuItem>
                        <MenuItem value="Balinese">Balinese</MenuItem>
                        <MenuItem value="Bengal">Bengal</MenuItem>
                        <MenuItem value="Birman">Birman</MenuItem>
                        <MenuItem value="Bombay">Bombay</MenuItem>
                        <MenuItem value="British Shorthair">
                          British Shorthair
                        </MenuItem>
                        <MenuItem value="Burmese">Burmese</MenuItem>
                        <MenuItem value="Chartreux">Chartreux</MenuItem>
                        <MenuItem value="Chausie">Chausie</MenuItem>
                        <MenuItem value="Cornish Rex">Cornish Rex</MenuItem>
                        <MenuItem value="Devon Rex">Devon Rex</MenuItem>
                        <MenuItem value="Donskoy">Donskoy</MenuItem>
                        <MenuItem value="Egyptian Mau">Egyptian Mau</MenuItem>
                        <MenuItem value="Exotic Shorthair">
                          Exotic Shorthair
                        </MenuItem>
                        <MenuItem value="Havana">Havana</MenuItem>
                        <MenuItem value="Highlander">Highlander</MenuItem>
                        <MenuItem value="Himalayan">Himalayan</MenuItem>
                        <MenuItem value="Household Pet Cat">
                          Household Pet Cat
                        </MenuItem>
                        <MenuItem value="Household Pet Kitten">
                          Household Pet Kitten
                        </MenuItem>
                        <MenuItem value="Japanese Bobtail">
                          Japanese Bobtail
                        </MenuItem>
                        <MenuItem value="Korat">Korat</MenuItem>
                        <MenuItem value="Kurilian Bobtail">
                          Kurilian Bobtail
                        </MenuItem>
                        <MenuItem value="Laperm">Laperm</MenuItem>
                        <MenuItem value="Maine Coon">Maine Coon</MenuItem>
                        <MenuItem value="Manx">Manx</MenuItem>
                        <MenuItem value="Minskin">Minskin</MenuItem>
                        <MenuItem value="Munchkin">Munchkin</MenuItem>
                        <MenuItem value="Nebelung">Nebelung</MenuItem>
                        <MenuItem value="Norwegian Forest Cat">
                          Norwegian Forest Cat
                        </MenuItem>
                        <MenuItem value="Ocicat">Ocicat</MenuItem>
                        <MenuItem value="Ojos Azules">Ojos Azules</MenuItem>
                        <MenuItem value="Oriental">Oriental</MenuItem>
                        <MenuItem value="Persian">Persian</MenuItem>
                        <MenuItem value="Peterbald">Peterbald</MenuItem>
                        <MenuItem value="Pixiebob">Pixiebob</MenuItem>
                        <MenuItem value="Ragdoll">Ragdoll</MenuItem>
                        <MenuItem value="Russian Blue">Russian Blue</MenuItem>
                        <MenuItem value="Savannah">Savannah</MenuItem>
                        <MenuItem value="Scottish Fold">Scottish Fold</MenuItem>
                        <MenuItem value="Selkirk Rex">Selkirk Rex</MenuItem>
                        <MenuItem value="Serengeti">Serengeti</MenuItem>
                        <MenuItem value="Siberian">Siberian</MenuItem>
                        <MenuItem value="Siamese">Siamese</MenuItem>
                        <MenuItem value="Singapura">Singapura</MenuItem>
                        <MenuItem value="Snowshoe">Snowshoe</MenuItem>
                        <MenuItem value="Sokoke">Sokoke</MenuItem>
                        <MenuItem value="Somali">Somali</MenuItem>
                        <MenuItem value="Sphynx">Sphynx</MenuItem>
                        <MenuItem value="Thai">Thai</MenuItem>
                        <MenuItem value="Tonkinese">Tonkinese</MenuItem>
                        <MenuItem value="Toyger">Toyger</MenuItem>
                        <MenuItem value="Turkish Angora">
                          Turkish Angora
                        </MenuItem>
                        <MenuItem value="Turkish Van">Turkish Van</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </TextField>
                    </>
                  ) : null}
                </div>
              </div>

              {/* color and gender */}
              <div className="reportlost-one-line">
                <div className="reportlost-input">
                  <TextField
                    select
                    label="Pet Color"
                    name="pet_color"
                    value={pet_color}
                    onChange={this.changeHandler}
                    variant="outlined"
                    required
                  >
                    <MenuItem value="white">White</MenuItem>
                    <MenuItem value="brown">Brown</MenuItem>
                    <MenuItem value="black">Black</MenuItem>
                    <MenuItem value="beige">Beige</MenuItem>
                    <MenuItem value="golden">Golden</MenuItem>
                    <MenuItem value="grey">Grey</MenuItem>
                    <MenuItem value="chocolate">Chocolate</MenuItem>
                    <MenuItem value="silver">Silver</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </TextField>
                </div>
                <div className="reportlost-input">
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Gender *
                    </FormLabel>
                    <RadioGroup
                      aria-label="pet_gender"
                      name="pet_gender"
                      value={pet_gender}
                      onChange={this.changeHandler}
                      required
                      row
                    >
                      <MyFormControlLabel
                        value="male"
                        control={<Radio />}
                        label="Male"
                      />

                      <MyFormControlLabel
                        value="female"
                        control={<Radio />}
                        label="Female"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>

              {/*dob and age */}
              <div className="reportlost-one-line">
                <div className="reportlost-input">
                  <TextField
                    label="Date of Birth"
                    variant="outlined"
                    type="date"
                    name="pet_dob"
                    value={pet_dob}
                    onChange={this.changeHandler}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      max: new Date(),
                    }}
                    required
                  />
                </div>
                <div className="reportlost-input">
                  <TextField
                    label="Age"
                    type="number"
                    variant="outlined"
                    value={pet_age}
                    onChange={this.changeHandler}
                    name="pet_age"
                    required
                  />
                </div>
              </div>

              {/* blood grp and mark */}
              <div className="reportlost-one-line">
                <div className="reportlost-input">
                  {!valuable_type ? (
                    <TextField
                      select
                      label="Blood Group"
                      value={pet_breed}
                      onChange={this.changeHandler}
                      variant="outlined"
                      required
                    >
                      <MenuItem disabled hidden></MenuItem>
                    </TextField>
                  ) : (
                    <div></div>
                  )}

                  {valuable_type === "Dog" ? (
                    <>
                      <TextField
                        select
                        label="Blood Group"
                        name="pet_bloodgrp"
                        value={pet_bloodgrp}
                        onChange={this.changeHandler}
                        variant="outlined"
                      >
                        <MenuItem hidden></MenuItem>
                        <MenuItem value="dea1.1+">DEA-1.1 +</MenuItem>
                        <MenuItem value="dea1.1-">DEA-1.1 -</MenuItem>
                        <MenuItem value="dea1.2+">DEA-1.2 +</MenuItem>
                        <MenuItem value="dea1.2-">DEA-1.2 -</MenuItem>
                        <MenuItem value="dea1.3+">DEA-3 +</MenuItem>
                        <MenuItem value="dea1.3-">DEA-3 -</MenuItem>
                        <MenuItem value="dea1.4+">DEA-4 +</MenuItem>
                        <MenuItem value="dea1.4-">DEA-4 -</MenuItem>
                        <MenuItem value="dea1.5+">DEA-5 +</MenuItem>
                        <MenuItem value="dea1.5-">DEA-5 -</MenuItem>
                        <MenuItem value="dea1.7+">DEA-7 +</MenuItem>
                        <MenuItem value="dea1.7-">DEA-7 -</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </TextField>
                    </>
                  ) : (
                    <div></div>
                  )}

                  {valuable_type === "Cat" ? (
                    <>
                      <TextField
                        select
                        label="Blood Group"
                        name="pet_bloodgrp"
                        value={pet_bloodgrp}
                        onChange={this.changeHandler}
                        variant="outlined"
                      >
                        <MenuItem hidden></MenuItem>
                        <MenuItem value="a+">A+</MenuItem>
                        <MenuItem value="a-">A-</MenuItem>
                        <MenuItem value="b+">B+</MenuItem>
                        <MenuItem value="b-">B-</MenuItem>
                        <MenuItem value="ab+">AB+</MenuItem>
                        <MenuItem value="ab-">AB-</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </TextField>
                    </>
                  ) : (
                    <div></div>
                  )}
                </div>

                <div className="reportlost-input">
                  <TextField
                    label="Identification Mark"
                    variant="outlined"
                    type="text"
                    value={pet_ident}
                    onChange={this.changeHandler}
                    name="pet_ident"
                    required
                  />
                </div>
              </div>

              {/* consultancy and dr phone no */}
              <div className="reportlost-one-line">
                <div className="reportlost-input">
                  <TextField
                    type="text"
                    label="Consulting Veterinary"
                    variant="outlined"
                    name="veternity"
                    value={veternity}
                    onChange={this.changeHandler}
                  />
                </div>
                <div className="reportlost-input">
                  <MuiPhoneInput
                    label="Doctor Phone Number"
                    defaultCountry="in"
                    value={dr_phone}
                    regions={[
                      "america",
                      "caribbean",
                      "asia",
                      "middle-east",
                      "oceania",
                      "europe",
                      "africa",
                    ]}
                    name="dr_phone"
                    variant="outlined"
                    required
                    onChange={(val) => this.setState({ dr_phone: val })}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          edge="end"
                          aria-label="personal-details-icon"
                        >
                          <ContactPhoneIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="reportlost-container-pet-parent-details-span">
              <h4 className="">Pet Parent Details</h4>

              {/* fisrt and last name */}
              <div className="reportlost-one-line">
                <div className="reportlost-input">
                  <TextField
                    type="text"
                    variant="outlined"
                    label="First Name"
                    name="parent_firstname"
                    value={parent_firstname}
                    onChange={this.changeHandler}
                    required
                  />
                </div>
                <div className="reportlost-input">
                  <TextField
                    type="text"
                    variant="outlined"
                    label="Last Name"
                    name="parent_lastname"
                    value={parent_lastname}
                    onChange={this.changeHandler}
                    required
                  />
                </div>
              </div>

              {/* email and phone1 */}
              <div className="reportlost-one-line">
                <div className="reportlost-input">
                  <TextField
                    type="email"
                    variant="outlined"
                    label="Email"
                    name="parent_email"
                    value={parent_email}
                    onChange={this.changeHandler}
                    required
                  />
                </div>

                <div className="reportlost-input">
                  <MuiPhoneInput
                    label="Emergency Phone Number 1"
                    defaultCountry="in"
                    value={phone_one}
                    regions={[
                      "america",
                      "caribbean",
                      "asia",
                      "middle-east",
                      "oceania",
                      "europe",
                      "africa",
                    ]}
                    name="phone_one"
                    variant="outlined"
                    required
                    onChange={(val) => this.setState({ phone_one: val })}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          edge="end"
                          aria-label="personal-details-icon"
                        >
                          <ContactPhoneIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </div>
              </div>

              {/* phone2 and phone3  */}
              <div className="reportlost-one-line">
                <div className="reportlost-input">
                  <MuiPhoneInput
                    label="Emergency Phone Number 2"
                    defaultCountry="in"
                    value={phone_two}
                    regions={[
                      "america",
                      "caribbean",
                      "asia",
                      "middle-east",
                      "oceania",
                      "europe",
                      "africa",
                    ]}
                    name="phone_two"
                    variant="outlined"
                    onChange={(val) => this.setState({ phone_two: val })}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          edge="end"
                          aria-label="personal-details-icon"
                        >
                          <ContactPhoneIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </div>
                <div className="reportlost-input">
                  <MuiPhoneInput
                    label="Emergency Phone Number 3"
                    defaultCountry="in"
                    value={phone_three}
                    regions={[
                      "america",
                      "caribbean",
                      "asia",
                      "middle-east",
                      "oceania",
                      "europe",
                      "africa",
                    ]}
                    name="phone_three"
                    variant="outlined"
                    onChange={(val) => this.setState({ phone_three: val })}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          edge="end"
                          aria-label="personal-details-icon"
                        >
                          <ContactPhoneIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="reportlost-container-owner-reward-span">
              <h4>
                <i className="fa fa-gift fa-2x"></i>
                <span>Owner Reward</span>
              </h4>

              <div className="reportlost-one-line">
                <div className="reportlost-input">
                  <TextField
                    select
                    label="Currency type"
                    name="rewardcurrency"
                    value={rewardcurrency}
                    onChange={this.changeHandler}
                  >
                    <MenuItem hidden></MenuItem>
                    <MenuItem value="Rupees">Indian Rupees</MenuItem>
                    <MenuItem value="U.S. dollar">U.S. dollar</MenuItem>
                    <MenuItem value="Euro">Euro</MenuItem>
                    <MenuItem value="Dirham">Dirham</MenuItem>
                    <MenuItem value="Dinar">Dinar</MenuItem>
                    <MenuItem value="Yen">Yen</MenuItem>
                    <MenuItem value="Afghani">Afghani</MenuItem>
                    <MenuItem value="Lek">Lek</MenuItem>
                    <MenuItem value="New Kwanza">New Kwanza</MenuItem>
                    <MenuItem value="Dram">Dram</MenuItem>
                    <MenuItem value="Bahamian dollar">Bahamian dollar</MenuItem>
                    <MenuItem value="Bahrain dinar">Bahrain dinar</MenuItem>
                    <MenuItem value="Taka">Taka</MenuItem>
                    <MenuItem value="Barbados dollar">Barbados dollar</MenuItem>
                    <MenuItem value="Belorussian ruble">
                      Belorussian ruble
                    </MenuItem>
                    <MenuItem value="Belize dollar">Belize dollar</MenuItem>
                    <MenuItem value="Ngultrum">Ngultrum</MenuItem>
                    <MenuItem value="Boliviano">Boliviano</MenuItem>
                    <MenuItem value="Convertible Mark">
                      Convertible Mark
                    </MenuItem>
                    <MenuItem value="Pula">Pula</MenuItem>
                    <MenuItem value="Real">Real</MenuItem>
                    <MenuItem value="Brunei dollar">Brunei dollar</MenuItem>
                    <MenuItem value="Lev">Lev</MenuItem>
                    <MenuItem value="Burundi franc">Burundi franc</MenuItem>
                    <MenuItem value="Riel">Riel</MenuItem>
                    <MenuItem value="Canadian dollar">Canadian dollar</MenuItem>
                    <MenuItem value="Cape Verdean escudo">
                      Cape Verdean escudo
                    </MenuItem>
                    <MenuItem value="Chilean Peso">Chilean Peso</MenuItem>
                    <MenuItem value="Chinese Yuan">Chinese Yuan</MenuItem>
                    <MenuItem value="Colombian Peso">Colombian Peso</MenuItem>
                    <MenuItem value="Franc">Franc</MenuItem>
                    <MenuItem value="United States dollar">
                      United States dollar
                    </MenuItem>
                    <MenuItem value="Colón">Colón</MenuItem>
                    <MenuItem value="Croatian">Croatian</MenuItem>
                    <MenuItem value="Cuban Peso">Cuban Peso</MenuItem>
                    <MenuItem value="Koruna">Koruna</MenuItem>
                    <MenuItem value="Danish Krone">Danish Krone</MenuItem>
                    <MenuItem value="Djiboutian franc">
                      Djiboutian franc
                    </MenuItem>
                    <MenuItem value="Dominican Peso">Dominican Peso</MenuItem>
                    <MenuItem value="Egyptian pound">Egyptian pound</MenuItem>
                    <MenuItem value="Colón; U.S. dollar">
                      Colón; U.S. dollar
                    </MenuItem>
                    <MenuItem value="Nakfa">Nakfa</MenuItem>
                    <MenuItem value="Birr">Birr</MenuItem>
                    <MenuItem value="Fiji dollar">Fiji dollar</MenuItem>
                    <MenuItem value="Dalasi">Dalasi</MenuItem>
                    <MenuItem value="Lari">Lari</MenuItem>
                    <MenuItem value="Cedi">Cedi</MenuItem>
                    <MenuItem value="Quetzal">Quetzal</MenuItem>
                    <MenuItem value="Guinean franc">Guinean franc</MenuItem>
                    <MenuItem value="Guyanese dollar">Guyanese dollar</MenuItem>
                    <MenuItem value="Gourde">Gourde</MenuItem>
                    <MenuItem value="Lempira">Lempira</MenuItem>
                    <MenuItem value="Forint">Forint</MenuItem>
                    <MenuItem value="Icelandic króna">Icelandic króna</MenuItem>
                    <MenuItem value="Rupiah">Rupiah</MenuItem>
                    <MenuItem value="Iraqi Dinar">Iraqi Dinar</MenuItem>
                    <MenuItem value="Shekel">Shekel</MenuItem>
                    <MenuItem value="Jamaican dollar">Jamaican dollar</MenuItem>
                    <MenuItem value="Jordanian dinar">Jordanian dinar</MenuItem>
                    <MenuItem value="Tenge">Tenge</MenuItem>
                    <MenuItem value="Kenya shilling">Kenya shilling</MenuItem>
                    <MenuItem value="Kiribati dollar">Kiribati dollar</MenuItem>
                    <MenuItem value="Won">Won</MenuItem>
                    <MenuItem value="Kuwaiti Dinar">Kuwaiti Dinar</MenuItem>
                    <MenuItem value="Som">Som</MenuItem>
                    <MenuItem value="New Kip">New Kip</MenuItem>
                    <MenuItem value="Lats">Lats</MenuItem>
                    <MenuItem value="Lebanese pound">Lebanese pound</MenuItem>
                    <MenuItem value="Maluti">Maluti</MenuItem>
                    <MenuItem value="Liberian dollar">Liberian dollar</MenuItem>
                    <MenuItem value="Libyan dinar">Libyan dinar</MenuItem>
                    <MenuItem value="Litas">Litas</MenuItem>
                    <MenuItem value="Denar">Denar</MenuItem>
                    <MenuItem value="Malagasy Ariary">Malagasy Ariary</MenuItem>
                    <MenuItem value="Ringgit">Ringgit</MenuItem>
                    <MenuItem value="Rufiyaa">Rufiyaa</MenuItem>
                    <MenuItem value="Ouguiya">Ouguiya</MenuItem>
                    <MenuItem value="Mauritian rupee">Mauritian rupee</MenuItem>
                    <MenuItem value="Mexican peso">Mexican peso</MenuItem>
                    <MenuItem value="Leu">Leu</MenuItem>
                    <MenuItem value="Togrog">Togrog</MenuItem>
                    <MenuItem value="Metical">Metical</MenuItem>
                    <MenuItem value="Kyat">Kyat</MenuItem>
                    <MenuItem value="Namibian dollar">Namibian dollar</MenuItem>
                    <MenuItem value="Australian dollar">
                      Australian dollar
                    </MenuItem>
                    <MenuItem value="Nepalese rupee">Nepalese rupee</MenuItem>
                    <MenuItem value="New Zealand dollar">
                      New Zealand dollar
                    </MenuItem>
                    <MenuItem value="Gold cordoba">Gold cordoba</MenuItem>
                    <MenuItem value="Naira">Naira</MenuItem>
                    <MenuItem value="Norwegian krone">Norwegian krone</MenuItem>
                    <MenuItem value="Omani rial">Omani rial</MenuItem>
                    <MenuItem value="Pakistani rupee">Pakistani rupee</MenuItem>
                    <MenuItem value="Palestine Pound">Palestine Pound</MenuItem>
                    <MenuItem value="Balboa; U.S. dollar">
                      Balboa; U.S. dollar
                    </MenuItem>
                    <MenuItem value="Kina">Kina</MenuItem>
                    <MenuItem value="Guaraní">Guaraní</MenuItem>
                    <MenuItem value="Nuevo sol (1991)">
                      Nuevo sol (1991)
                    </MenuItem>
                    <MenuItem value="Peso">Peso</MenuItem>
                    <MenuItem value="Zloty">Zloty</MenuItem>
                    <MenuItem value="Qatari riyal">Qatari riyal</MenuItem>
                    <MenuItem value="Romanian Rupee">Romanian Rupee</MenuItem>
                    <MenuItem value="Ruble">Ruble</MenuItem>
                    <MenuItem value="Rwandan franc">Rwandan franc</MenuItem>
                    <MenuItem value="East Caribbean dollar">
                      East Caribbean dollar
                    </MenuItem>
                    <MenuItem value="Tala">Tala</MenuItem>
                    <MenuItem value="Dobra">Dobra</MenuItem>
                    <MenuItem value="Riyal">Riyal</MenuItem>
                    <MenuItem value="Serbian Dinar">Serbian Dinar</MenuItem>
                    <MenuItem value="Seychelles rupee">
                      Seychelles rupee
                    </MenuItem>
                    <MenuItem value="Leone">Leone</MenuItem>
                    <MenuItem value="Singapore dollar">
                      Singapore dollar
                    </MenuItem>
                    <MenuItem value="Solomon Islands dollar">
                      Solomon Islands dollar
                    </MenuItem>
                    <MenuItem value="Somali shilling">Somali shilling</MenuItem>
                    <MenuItem value="Rand">Rand</MenuItem>
                    <MenuItem value="Sri Lankan rupee">
                      Sri Lankan rupee
                    </MenuItem>
                    <MenuItem value="Sudanese Pound">Sudanese Pound</MenuItem>
                    <MenuItem value="Surinamese dollar">
                      Surinamese dollar
                    </MenuItem>
                    <MenuItem value="Lilangeni">Lilangeni</MenuItem>
                    <MenuItem value="Krona">Krona</MenuItem>
                    <MenuItem value="Swiss franc">Swiss franc</MenuItem>
                    <MenuItem value="Syrian pound">Syrian pound</MenuItem>
                    <MenuItem value="Taiwan dollar">Taiwan dollar</MenuItem>
                    <MenuItem value="somoni">somoni</MenuItem>
                    <MenuItem value="Tanzanian shilling">
                      Tanzanian shilling
                    </MenuItem>
                    <MenuItem value="Baht">Baht</MenuItem>
                    <MenuItem value="CFA Franc">CFA Franc</MenuItem>
                    <MenuItem value="Pa’anga">Pa’anga</MenuItem>
                    <MenuItem value="Trinidad and Tobago dollar">
                      Trinidad and Tobago dollar
                    </MenuItem>
                    <MenuItem value="Tunisian dinar">Tunisian dinar</MenuItem>
                    <MenuItem value="Turkish lira (YTL)">
                      Turkish lira (YTL)
                    </MenuItem>
                    <MenuItem value="Manat">Manat</MenuItem>
                    <MenuItem value="Tuvaluan Dollar">Tuvaluan Dollar</MenuItem>
                    <MenuItem value="Ugandan new shilling">
                      Ugandan new shilling
                    </MenuItem>
                    <MenuItem value="Hryvnia">Hryvnia</MenuItem>
                    <MenuItem value="U.A.E. Dirham">U.A.E. Dirham</MenuItem>
                    <MenuItem value="Pound sterling">Pound sterling</MenuItem>
                    <MenuItem value="Dollar">Dollar</MenuItem>
                    <MenuItem value="Uruguay peso">Uruguay peso</MenuItem>
                    <MenuItem value="Uzbekistani sum">Uzbekistani sum</MenuItem>
                    <MenuItem value="Vatu">Vatu</MenuItem>
                    <MenuItem value="Bolivar">Bolivar</MenuItem>
                    <MenuItem value="Dong">Dong</MenuItem>
                    <MenuItem value="Rial">Rial</MenuItem>
                    <MenuItem value="Kwacha">Kwacha</MenuItem>
                  </TextField>
                </div>

                <div className="reportlost-input">
                  <TextField
                    label="Reward"
                    type="number"
                    variant="outlined"
                    name="reward"
                    value={reward}
                    onChange={this.changeHandler}
                  />
                </div>
              </div>

              <div className="reportlost-container-owner-reward-btns">
                <Button variant="contained" onClick={this.handleBack}>
                  Back
                </Button>

                <Button
                  variant="contained"
                  type="submit"
                  style={{ backgroundColor: "#ffc107" }}
                >
                  Submit
                </Button>

                <Button
                  type="button"
                  onClick={() => {
                    window.location.href =
                      "/dashboard/" + localStorage.getItem("uid");
                  }}
                  variant="contained"
                >
                  CANCEL
                </Button>
              </div>
            </div>
          </div>
        );
      default:
        return "Unknown stepIndex";
    }
  };

  handleStepChange = (e, stepCount) => {
    // Implement your logic for section change here
    console.log(`Changing to section: ${stepCount}`);
    this.setState({ activeStep: stepCount });
  };

  handleNext = () => {
    this.setState((prevActiveStep) => {
      console.log("prev", prevActiveStep);
      return {
        activeStep: prevActiveStep.activeStep + 1,
      };
    });
  };

  handleBack = () => {
    this.setState((prevActiveStep) => {
      return {
        activeStep: prevActiveStep.activeStep - 1,
      };
    });
  };

  handleReset = () => {
    this.setState({ activeStep: 0 });
  };

  showSidebar(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      sidebar: !currentState.sidebar,
    }));
  }

  showSubMenu(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      subMenu: !currentState.subMenu,
    }));
  }

  render() {
    const {
      sidebar,
      subMenu,
      alignment,
      tag_number,
      valuable_type,
      parent_firstname,
      parent_lastname,
      phone_one,
      phone_two,
      parent_email,
      parent_altemail,
      reward,
      rewardcurrency,
      pet_age,
      pet_name,
      pet_breed,
      pet_gender,
      pet_color,
      pet_dob,
      pet_bloodgrp,
      pet_ident,
      veternity,
      dr_phone,
      phone_three,
    } = this.state;

    document.onreadystatechange = () => {
      this.setState({ showLoader: true });
      if (document.readyState === "complete") {
        this.setState({ showLoader: false });
      }
    };

    return (
      <>
        <Navbar />

        <div
          className={`reportLost-main-wrapper ${
            this.state.activeStep !== 3 ? "bg-gray-height" : ""
          }`}
        >
          <div className="reportLost-left-wrapper">
            <div className="reportLost-sidebar-toggle-btn-wrapper">
              <button
                className="reportLost-sidebar-toggle-btn"
                onClick={this.showSidebar}
              >
                {sidebar ? (
                  <BsIcons.BsLayoutSidebarInset />
                ) : (
                  <BsIcons.BsLayoutSidebarInsetReverse />
                )}
              </button>
            </div>

            <div
              className={
                sidebar
                  ? "reportLost-sidebar-container-hide"
                  : "reportLost-sidebar-container"
              }
            >
              <button
                className="reportLost-sidebar-menu-link"
                onClick={this.showSubMenu}
              >
                MY TAGS
                {subMenu ? (
                  <AiIcons.AiFillCaretDown />
                ) : (
                  <AiIcons.AiFillCaretUp />
                )}
              </button>

              <ul
                className={
                  subMenu
                    ? "reportLost-sidebar-menu-ul-hide"
                    : "reportLost-sidebar-menu-ul"
                }
              >
                <li>
                  <button
                    className="reportLost-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    ALL TAGS
                  </button>
                </li>

                <li>
                  <button
                    className="reportLost-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    ACTIVE | INACTIVE TAGS
                  </button>
                </li>

                <li>
                  <button
                    className="reportLost-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    LOST | FOUND TAGS
                  </button>
                </li>
              </ul>

              <button
                className="reportLost-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/dashboard/" + this.props.match.params.tag_number;
                }}
              >
                ACTIVATE TAG
              </button>

              {/* <button
                className="reportLost-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/#/calendar/" + this.props.match.params.tag_number;
                }}
              >
                CALENDAR
              </button> */}

              <button
                className="reportLost-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/scan-notification/" + localStorage.getItem("uid");
                }}
              >
                NOTIFICATIONS
              </button>

              <button
                className="reportLost-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/#/pet-parent-details/" +
                    this.props.match.params.tag_number;
                }}
              >
                PET PARENT DETAILS
              </button>

              <button
                className="reportLost-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/#/pet-details/" + this.props.match.params.tag_number;
                }}
              >
                PET DETAILS
              </button>

              <button
                className="reportLost-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "https://dashboard.tag8.in/#/medical-dashboard/" +
                    this.props.match.params.tag_number;
                }}
              >
                PET MEDICAL DETAILS
              </button>
            </div>
          </div>

          <div className="reportLost-right-wrapper">
            <div className="reportLost-main-form-div">
              <form onSubmit={this.submitHandler} className="main-form">
                {/* <Stepper
                  activeStep={this.state.activeStep}
                  alternativeLabel
                  className=""
                >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper> */}
                <div className="tabs-div">
                  <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    value={this.state.activeStep}
                    onChange={this.handleStepChange}
                  >
                    {steps.map((label, index) => {
                      return <Tab label={label} key={index + label}></Tab>;
                    })}
                  </Tabs>
                </div>

                <div
                  className="container uppercontainer bg-white px-0 p-info text-center reportlostheight"
                  style={{
                    height: "100%",
                  }}
                >
                  {this.getStepContent(this.state.activeStep)}
                </div>

                {this.state.showLoader && (
                  <div className="loader-container">
                    <div className="loader">
                      <img
                        src={
                          "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                        }
                        alt="Loading..."
                        loading="lazy"
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>
                  </div>
                )}

                <Snackbar
                  open={this.state.snackbaropen}
                  autoHideDuration={3000}
                  onClose={this.snackbarClose}
                  message={this.state.snackbarmsg}
                  action={[
                    <IconButton
                      arial-label="Close"
                      color="inherit"
                      onClick={this.snackbarClose}
                    >
                      X
                    </IconButton>,
                  ]}
                />
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}
