import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "font-awesome/css/font-awesome.min.css";
import "./addnewrecord.component.css";
import {
  Breadcrumbs,
  Button,
  Link,
  TextareaAutosize,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import MaterialFileUpload from "react-material-file-upload";
// import { ScrollableTabsButtonAuto } from "@mui/lab";
import {
  PhoneAndroid as PhoneIcon,
  Favorite,
  Person as PersonPinIcon,
} from "@material-ui/icons";
import { AiOutlineUpload, AiFillFilePdf, AiFillFile } from "react-icons/ai";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import axios from "axios";
import * as AiIcons from "react-icons/ai";
import { switchUrls } from "../../../api/index.js";

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) === " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}

const baseURL = switchUrls("genie");

let medication_start_date = 1;
let usertoken = readCookie("token");
const sectionMap = {
  0: "Vaccination",
  1: "Surgery",
  2: "Allergy",
  3: "Medication",
  4: "Medicalcondition",
  5: "Measurement",
};
export default class AddNewRecord extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imageUrl: null,
      selectedImage: null,
      vaccination_reminder: false,
      surgery_reminder: false,
      allergy_reminder: false,
      medication_reminder: false,
      medicalcondition_reminder: false,
      measurement_reminder: false,
      tag_number: localStorage.getItem("tag_number"),
      // selectedSection: "vaccination",
      Type: "",
      value: 0,
      vaccination_id: "",
      Vaccination_name: "",
      VaccinationDoctor_name: "",
      VaccinationAdministration_date: "",
      VaccinationNotes: "",
      VaccinationTitle: "",
      VaccinationEverynumber: "",
      VaccinationEvery: "",
      VaccinationNext: "",
      VaccinationRemind_me: "",
      VaccinationReminderNotes: "",
      VaccinationDone: false,

      allergy_id: "",
      Allergy_name: "",
      AllergyDoctor_name: "",
      AllergyDiagnosis_date: "",
      AllergySeverity: "",
      AllergyNotes: "",
      AllergyTitle: "",
      AllergyEverynumber: "",
      AllergyEvery: "",
      AllergyNext: "",
      AllergyRemind_me: "",
      AllergyReminderNotes: "",
      AllergyDone: false,

      surgery_id: "",
      Surgery_name: "",
      SurgeryDoctor_name: "",
      Surgery_date: "",
      SurgerySeverity: "",
      SurgeryNotes: "",
      SurgeryTitle: "",
      SurgeryEverynumber: "",
      SurgeryEvery: "",
      SurgeryNext: "",
      SurgeryRemind_me: "",
      SurgeryReminderNotes: "",
      SurgeryDone: false,

      medication_id: "",
      Medication_name: "",
      MedicationDoctor_name: "",
      Medication_startdate: "",
      Medication_enddate: "",
      MedicationDosage: "",
      MedicationNotes: "",
      MedicationTitle: "",
      MedicationEverynumber: "",
      MedicationEvery: "",
      MedicationNext: "",
      MedicationRemind_me: "",
      MedicationReminderNotes: "",
      MedicationDone: false,

      medicalcondition_id: "",
      Medicalcondition_type: "",
      MedicalconditionDoctor_name: "",
      MedicalconditionDiagnosed_date: "",
      Medicalcondition_enddate: "",
      MedicalconditionSeverity: "",
      MedicalconditionNotes: "",
      MedicalconditionTitle: "",
      MedicalconditionEverynumber: "",
      MedicalconditionEvery: "",
      MedicalconditionNext: "",
      MedicalconditionRemind_me: "",
      MedicalconditionReminderNotes: "",
      MedicalconditionDone: false,

      measurement_id: "",
      Height: "",
      Weight: "",
      Measurement_date: "",
      Measuredby: "",
      MeasurementNotes: "",
      MeasurementTitle: "",
      MeasurementEverynumber: "",
      MeasurementEvery: "",
      MeasurementNext: "",
      MeasurementRemind_me: "",
      MeasurementReminderNotes: "",
      MeasurementDone: false,

      Vaccination_Reportname: "",
      Vaccination_Reportlocation: "",
      Allergy_Reportname: "",
      Allergy_Reportlocation: "",
      Surgery_Reportname: "",
      Surgery_Reportlocation: "",
      Medication_Reportname: "",
      Medication_Reportlocation: "",
      Medicalcondition_Reportname: "",
      Medicalcondition_Reportlocation: "",
      Measurement_Reportname: "",
      Measurement_Reportlocation: "",
      message: "",
      files: [],
      vaccinationFiles: [],
      surgeryFiles: [],
      allergyFiles: [],
      medicationFiles: [],
      medicalConditionFiles: [],
      measurementFiles: [],
      file: "",
      setfile: false,
    };
  }

  getSectionFromIndex = (index) => {
    return sectionMap[index];
  };
  componentDidMount() {
    const defaultSection = this.getSectionFromIndex(this.state.value);
    this.setState({ Type: defaultSection });
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  change_new_handler = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    medication_start_date = e.target.value;
  };

  handleChange = (event) => {
    const file = event[0];
    // console.log(event, "filess");
    // console.log(file, "file2");
    const fileName = file.name;
    const fileSize = file.size;

    const fileExtension = fileName.split(".").pop();
    const size = fileSize / (1024 * 1024); // in MB

    const regex = /\./gm;

    const isValidFileExtension = [
      "pdf",
      "docx",
      "docs",
      "png",
      "jpg",
      "jpeg",
      "csv",
      "xlsx",
    ].includes(fileExtension);
    const isValidSize = size < 5;
    const isValidFileName = (fileName.match(regex) || []).length === 1;

    if (isValidFileExtension && isValidSize && isValidFileName) {
      this.setState({ message: "" });

      // Update files based on the current section
      if (this.state.value === 0) {
        this.setState((prevState) => ({
          vaccinationFiles: [...prevState.vaccinationFiles, file],
        }));
      } else if (this.state.value === 1) {
        this.setState((prevState) => ({
          surgeryFiles: [...prevState.surgeryFiles, file],
        }));
      } else if (this.state.value === 2) {
        this.setState((prevState) => ({
          allergyFiles: [...prevState.allergyFiles, file],
        }));
      } else if (this.state.value === 3) {
        this.setState((prevState) => ({
          medicationFiles: [...prevState.medicationFiles, file],
        }));
      } else if (this.state.value === 4) {
        this.setState((prevState) => ({
          medicalConditionFiles: [...prevState.medicalConditionFiles, file],
        }));
      } else if (this.state.value === 5) {
        this.setState((prevState) => ({
          measurementFiles: [...prevState.measurementFiles, file],
        }));
      }
    } else if (!isValidFileName) {
      this.setState({
        message:
          "Oops!! File name is not appropriate. Kindly rename the file and try again.",
      });
    } else if (!isValidSize) {
      this.setState({ message: "File size should be less than 5 MB" });
    } else if (!isValidFileExtension) {
      this.setState({
        message: `File with extension .${fileExtension} is not allowed!`,
      });
    }
  };
  handleRemove = (index) => {
    if (this.state.value === 0) {
      const updatedFiles = [...this.state.vaccinationFiles];
      updatedFiles.splice(index, 1);
      this.setState({ vaccinationFiles: updatedFiles });
    } else if (this.state.value === 1) {
      const updatedFiles = [...this.state.surgeryFiles];
      updatedFiles.splice(index, 1);
      this.setState({ surgeryFiles: updatedFiles });
    } else if (this.state.value === 2) {
      const updatedFiles = [...this.state.allergyFiles];
      updatedFiles.splice(index, 1);
      this.setState({ allergyFiles: updatedFiles });
    } else if (this.state.value === 3) {
      const updatedFiles = [...this.state.medicationFiles];
      updatedFiles.splice(index, 1);
      this.setState({ medicationFiles: updatedFiles });
    } else if (this.state.value === 4) {
      const updatedFiles = [...this.state.medicalConditionFiles];
      updatedFiles.splice(index, 1);
      this.setState({ medicalConditionFiles: updatedFiles });
    } else if (this.state.value === 5) {
      const updatedFiles = [...this.state.measurementFiles];
      updatedFiles.splice(index, 1);
      this.setState({ measurementFiles: updatedFiles });
    }
  };
  appendFilesToFormData = (fd, fieldName, fileArray) => {
    // console.log(fileArray, "fileArray");
    if (fileArray.length > 0) {
      fileArray.forEach((file) => {
        fd.append(fieldName, file);
        // console.log(`Appended ${file.name} to ${fieldName}`);
      });
    }
  };

  submitHandler = async (e) => {
    e.preventDefault();

    const medicalTypes = [
      { type: "Vaccination", files: this.state.vaccinationFiles },
      { type: "Surgery", files: this.state.surgeryFiles },
      { type: "Allergy", files: this.state.allergyFiles },
      { type: "Medication", files: this.state.medicationFiles },
      { type: "Medicalcondition", files: this.state.medicalConditionFiles },
      { type: "Measurement", files: this.state.measurementFiles },
    ];

    try {
      // Make the MedicalActivation API call only once
      const activationData = {
        tag_number: this.state.tag_number,
        ...this.state,
      };

      const activationResponse = await axios.post(
        `${baseURL}/api/pet/MedicalActivation/${localStorage.getItem(
          "tag_number"
        )}`,
        activationData,
        { headers: { Authorization: usertoken } }
      );

      // console.log("Medical Activation Response:", activationResponse.data);

      const resData = activationResponse.data;
      const { responseIds } = resData;
      // Process each medical type and upload files if they exist
      const uploadPromises = medicalTypes.map(async (medical) => {
        const { type, files } = medical;

        if (!files || files.length === 0) return; // Skip if no files

        // Find the corresponding ID for this medical type
        const typeIdObj = responseIds.find((item) => item.type === type);
        if (!typeIdObj) {
          // console.warn(`No ID found for type: ${type}`);
          return;
        }

        const fd = new FormData();
        this.appendFilesToFormData(fd, "image", files);

        fd.append("tag_number", this.state.tag_number);
        fd.append("Type", type);

        // Append the correct ID based on the medical type
        const idFieldMap = {
          Vaccination: "vaccination_id",
          Allergy: "allergy_id",
          Surgery: "surgery_id",
          Medication: "medication_id",
          Medicalcondition: "medicalcondition_id",
          Measurement: "measurement_id",
        };

        const idField = idFieldMap[type];
        if (idField) {
          fd.append(idField, typeIdObj.id);
        } else {
          // console.log(`No ID field mapping found for type: ${type}`);
        }

        // // Log FormData entries for debugging
        // for (let [key, value] of fd.entries()) {
        //   if (value instanceof File) {
        //     console.log(`${key}: [File] ${value.name}`);
        //   } else {
        //     console.log(`${key}: ${value}`);
        //   }
        // }

        // console.log(fd, "fdddd");

        // Upload the files
        const uploadResponse = await axios.post(
          `${baseURL}/api/pet/reports-upload`,
          fd,
          { headers: { Authorization: usertoken } } // Ensure headers if needed
        );

        // console.log(`${type} Upload Response:`, uploadResponse.data);
      });

      await Promise.all(uploadPromises);
      window.location.reload();
    } catch (error) {
      if (error.response) {
        console.error("Error data:", error.response.data);
        console.error("Error status:", error.response.status);
        console.error("Error headers:", error.response.headers);
      } else if (error.request) {
        console.error("Error request:", error.request);
      } else {
        console.error("Error message:", error.message);
      }
      this.props.closeDialog();
    }
  };

  handleSectionChange = (section) => {
    // Implement your logic for section change here
    // console.log(`Changing to section: ${section}`);
    this.setState({ value: section });
    const currentSection = sectionMap[section];
    this.setState({ Type: currentSection });
  };

  render() {
    const {
      Type,
      vaccination_reminder,
      surgery_reminder,
      medicalcondition_reminder,
      measurement_reminder,
      SurgeryTitle,
      SurgeryNext,
      SurgeryRemind_me,
      SurgeryReminderNotes,
      SurgeryNotes,
      Allergy_name,
      AllergyDoctor_name,
      AllergyDiagnosis_date,
      AllergySeverity,
      AllergyNotes,
      allergy_reminder,
      AllergyTitle,
      AllergyNext,
      AllergyRemind_me,
      AllergyReminderNotes,
      medication_reminder,
      medication_start_date,
      value,
    } = this.state;
    // console.log("Initial State: ", this.state);
    return (
      <>
        <form onSubmit={this.submitHandler} className="addNewRecord-form">
          <Tabs
            value={value}
            onChange={this.handleSectionChange}
            aria-label="icon label tabs example"
            variant="scrollable"
            scrollButtons="auto"
          >
            <div className="d-flex flex-column align-items-center">
              <img
                src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/vaccinated.png"
                alt="VaccinationImg"
                className="img-medicla-tab"
              />
              <Tab
                // icon={<PhoneIcon />}
                label="Vaccination"
                value={0}
                onClick={() => this.handleSectionChange(0)}
              />
            </div>

            <div className="d-flex flex-column align-items-center">
              <img
                src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/surgery.png"
                alt="VaccinationImg"
                className="img-medicla-tab"
              />
              <Tab
                // icon={<Favorite />}
                label="Surgery"
                value={1}
                onClick={() => this.handleSectionChange(1)}
              />
            </div>

            <div className="d-flex flex-column align-items-center">
              <img
                src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/allergy.png"
                alt="VaccinationImg"
                className="img-medicla-tab"
              />
              <Tab
                // icon={<PersonPinIcon />}
                label="Allergy"
                value={2}
                onClick={() => this.handleSectionChange(2)}
              />
            </div>

            <div className="d-flex flex-column align-items-center">
              <img
                src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/medication.png"
                alt="VaccinationImg"
                className="img-medicla-tab"
              />
              <Tab
                // icon={<PhoneIcon />}
                label="Medication"
                value={3}
                onClick={() => this.handleSectionChange(3)}
              />
            </div>

            <div className="d-flex flex-column align-items-center">
              <img
                src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/medical-condition.png"
                alt="VaccinationImg"
                className="img-medicla-tab"
              />
              <Tab
                // icon={<Favorite />}
                label="Medical Condition"
                value={4}
                onClick={() => this.handleSectionChange(4)}
              />
            </div>

            <div className="d-flex flex-column align-items-center">
              <img
                src="https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/measure.png"
                alt="VaccinationImg"
                className="img-medicla-tab"
              />
              <Tab
                // icon={<PersonPinIcon />}
                label="Measurement"
                value={5}
                onClick={() => this.handleSectionChange(5)}
              />
            </div>
            {/* <ScrollableTabsButtonAuto /> */}
          </Tabs>
          {value === 0 && (
            <div className="addNewRecord-popup-wrapper">
              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  label="Vaccination Name"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="Vaccination_name"
                  value={this.state.Vaccination_name}
                  required
                  onChange={this.changeHandler}
                />
                <TextField
                  label="Doctor's Name"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="VaccinationDoctor_name"
                  onChange={this.changeHandler}
                  value={this.state.VaccinationDoctor_name}
                  required
                />
              </div>

              {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  label="Administration Date"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="VaccinationAdministration_date"
                  type="date"
                  required
                  onChange={this.changeHandler}
                  value={this.state.VaccinationAdministration_date}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="Notes"
                  // multiline
                  // rows={2}
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-textarea"
                  name="VaccinationNotes"
                  value={this.state.VaccinationNotes}
                  onChange={this.changeHandler}
                />
              </div>

              {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

              <Button
                onClick={() => {
                  this.setState({
                    vaccination_reminder: !vaccination_reminder,
                  });
                }}
                startIcon={<AddAlertIcon />}
              >
                Set Reminder
              </Button>

              {vaccination_reminder ? (
                <div className="addNewRecord-set-reminder">
                  {/* <div className="">
                        <label>Vaccination</label>

                        <Button
                          className=""
                          onClick={() => {
                            this.setState({
                              vaccination_reminder: !vaccination_reminder,
                            });
                          }}
                        >
                          <ExpandMoreIcon />
                        </Button>
                      </div> */}

                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      label="Reminder Title"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="VaccinationTitle"
                      onChange={this.changeHandler}
                      value={this.state.VaccinationTitle}
                    />
                    <TextField
                      label="Next Appointment Date"
                      type="date"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="VaccinationNext"
                      onChange={this.changeHandler}
                      value={this.state.VaccinationNext}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>

                  {/* <div className="addNewRecord-popup-wrapper-div">
                   
                  </div> */}

                  <div className="addNewRecord-popup-wrapper-div">
                    <FormControl
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                    >
                      <InputLabel>Remind Me</InputLabel>
                      <Select
                        value={this.state.VaccinationRemind_me}
                        name="VaccinationRemind_me"
                        onChange={this.changeHandler}
                        label="Remind Me"
                      >
                        <MenuItem value="" disabled>
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="On The Same Day">
                          On The Same Day
                        </MenuItem>
                        <MenuItem value="1 Day Before">1 Day Before</MenuItem>
                        <MenuItem value="2 Days Before">2 Days Before</MenuItem>
                        <MenuItem value="3 Days Before">3 Days Before</MenuItem>
                        <MenuItem value="15 Days Before">
                          15 Days Before
                        </MenuItem>
                        <MenuItem value="1 Month Before">
                          1 Month Before
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      label="Notes"
                      // multiline
                      // rows={2}
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-textarea"
                      name="VaccinationReminderNotes"
                      onChange={this.changeHandler}
                      value={this.state.VaccinationReminderNotes}
                    />
                  </div>

                  {/* <div className="addNewRecord-popup-wrapper-div">
                   
                  </div> */}
                </div>
              ) : (
                ""
              )}

              <div className="addNewRecord-uploadReport-container">
                <label>
                  <AiIcons.AiOutlineUpload /> Report
                </label>

                <MaterialFileUpload
                  onChange={this.handleChange}
                  multiple={false}
                  accept={[
                    ".pdf",
                    ".docx",
                    ".docs",
                    ".png",
                    ".jpg",
                    ".jpeg",
                    ".csv",
                    ".xlsx",
                  ]}
                  maxFileSize={5 * 1024 * 1024}
                />

                {this.state.vaccinationFiles.map((file, index) => (
                  <span key={index} className="file-container">
                    {file.type === "application/pdf" ? (
                      <AiIcons.AiFillFilePdf className="file-icon" />
                    ) : (
                      <AiIcons.AiFillFile className="file-icon" />
                    )}
                    <span className="file-name">{file.name}</span>
                    <button
                      className="remove-button"
                      onClick={() => this.handleRemove(index)}
                    >
                      <AiIcons.AiOutlineClose className="remove-icon" />
                    </button>
                  </span>
                ))}

                <label style={{ color: "red" }}>{this.state.message}</label>
              </div>
            </div>
          )}

          {value === 1 && (
            <div className="addNewRecord-popup-wrapper">
              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  label="Surgery Name"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="Surgery_name"
                  required
                  onChange={this.changeHandler}
                  value={this.state.Surgery_name}
                />
                <TextField
                  label="Doctor's Name"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="SurgeryDoctor_name"
                  onChange={this.changeHandler}
                  value={this.state.SurgeryDoctor_name}
                />
              </div>

              {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  label="Surgery Date"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="Surgery_date"
                  type="date"
                  required
                  onChange={this.changeHandler}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={this.state.Surgery_date}
                />
                <FormControl
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                >
                  <InputLabel>Severity</InputLabel>
                  <Select
                    value={this.state.SurgerySeverity}
                    name="SurgerySeverity"
                    onChange={this.changeHandler}
                    label="Severity"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="Minor">Minor</MenuItem>
                    <MenuItem value="Medium">Medium</MenuItem>
                    <MenuItem value="Major">Major</MenuItem>
                    <MenuItem value="Critical">Critical</MenuItem>
                  </Select>
                </FormControl>
              </div>

              {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  label="Notes"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-textarea"
                  rowsMin={2}
                  name="SurgeryNotes"
                  onChange={this.changeHandler}
                  value={SurgeryNotes}
                />
              </div>

              <Button
                onClick={() => {
                  this.setState({ surgery_reminder: !surgery_reminder });
                }}
                startIcon={<AddAlertIcon />}
              >
                Set Reminder
              </Button>

              {surgery_reminder ? (
                <div className="addNewRecord-set-reminder">
                  {/* <div className="">
                          <p >Surgery</p>

                          <Button
                            className="p-0 myexp-btn"
                            onClick={() => {
                              this.setState({
                                surgery_reminder: !surgery_reminder,
                              });
                            }}
                          >
                            <ExpandMoreIcon />
                          </Button>
                        </div> */}

                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      label="Reminder Title"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="SurgeryTitle"
                      onChange={this.changeHandler}
                      value={SurgeryTitle}
                    />
                    <TextField
                      label="Next Appointment Date"
                      type="date"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="SurgeryNext"
                      onChange={this.changeHandler}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={SurgeryNext}
                    />
                  </div>

                  {/* <div className="addNewRecord-popup-wrapper-div">
                   
                  </div> */}

                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      select
                      label="Remind Me"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="SurgeryRemind_me"
                      value={SurgeryRemind_me}
                      onChange={this.changeHandler}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="On The Same Day">
                        On The Same Day
                      </MenuItem>
                      <MenuItem value="1 Day Before">1 Day Before</MenuItem>
                      <MenuItem value="2 Days Before">2 Days Before</MenuItem>
                      <MenuItem value="3 Days Before">3 Days Before</MenuItem>
                      <MenuItem value="15 Days Before">15 Days Before</MenuItem>
                      <MenuItem value="1 Month Before">1 Month Before</MenuItem>
                    </TextField>
                    <TextField
                      label="Notes"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-textarea"
                      rowsMin={2}
                      name="SurgeryReminderNotes"
                      onChange={this.changeHandler}
                      value={SurgeryReminderNotes}
                    />
                  </div>

                  {/* <div className="addNewRecord-popup-wrapper-div">
                   
                  </div> */}
                </div>
              ) : (
                ""
              )}

              <div className="addNewRecord-uploadReport-container">
                <label>
                  <AiIcons.AiOutlineUpload />
                  Report
                </label>

                <MaterialFileUpload
                  onChange={this.handleChange}
                  multiple={false}
                  accept={[
                    ".pdf",
                    ".docx",
                    ".docs",
                    ".png",
                    ".jpg",
                    ".jpeg",
                    ".csv",
                    ".xlsx",
                  ]}
                  maxFileSize={5 * 1024 * 1024}
                />

                {this.state.surgeryFiles.map((file, index) => (
                  <span key={index} className="file-container">
                    {file.type === "application/pdf" ? (
                      <AiIcons.AiFillFilePdf className="file-icon" />
                    ) : (
                      <AiIcons.AiFillFile className="file-icon" />
                    )}
                    <span className="file-name">{file.name}</span>
                    <button
                      className="remove-button"
                      onClick={() => this.handleRemove(index)}
                    >
                      <AiIcons.AiOutlineClose className="remove-icon" />
                    </button>
                  </span>
                ))}

                <label style={{ color: "red" }}>{this.state.message}</label>
              </div>
            </div>
          )}

          {value === 2 && (
            <div className="addNewRecord-popup-wrapper">
              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  label="Allergy Name"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="Allergy_name"
                  required
                  onChange={this.changeHandler}
                  value={Allergy_name}
                />
                <TextField
                  label="Doctor's Name"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="AllergyDoctor_name"
                  onChange={this.changeHandler}
                  value={AllergyDoctor_name}
                />
              </div>

              {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  label="Diagnosis Date"
                  type="date"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="AllergyDiagnosis_date"
                  required
                  onChange={this.changeHandler}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={AllergyDiagnosis_date}
                />
                <TextField
                  select
                  label="Severity"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="AllergySeverity"
                  value={AllergySeverity}
                  onChange={this.changeHandler}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="RAST 0 - Undetectable">
                    RAST 0 - Undetectable
                  </MenuItem>
                  <MenuItem value="RAST 1 - Low">RAST 1 - Low</MenuItem>
                  <MenuItem value="RAST 2- Moderate">RAST 2- Moderate</MenuItem>
                  <MenuItem value="RAST 3 - High">RAST 3 - High</MenuItem>
                  <MenuItem value="RAST 4 - Very High">
                    RAST 4 - Very High
                  </MenuItem>
                  <MenuItem value="RAST 5 - Ultra High">
                    RAST 5 - Ultra High
                  </MenuItem>
                  <MenuItem value="RAST 6 - Extremely High">
                    RAST 6 - Extremely High
                  </MenuItem>
                </TextField>
              </div>

              {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  label="Notes"
                  rowsMin={2}
                  className="addNewRecord-popup-wrapper-div-textarea"
                  name="AllergyNotes"
                  onChange={this.changeHandler}
                  value={AllergyNotes}
                />
              </div>

              <Button
                onClick={() => {
                  this.setState({ allergy_reminder: !allergy_reminder });
                }}
                startIcon={<AddAlertIcon />}
              >
                Set Reminder
              </Button>

              {allergy_reminder ? (
                <div className="addNewRecord-set-reminder">
                  {/* <div className="">
                        <p >Allergy</p>

                        <Button
                          className="p-0 myexp-btn"
                          style={{ marginLeft: "80px" }}
                          onClick={() => {
                            this.setState({
                              allergy_reminder: !allergy_reminder,
                            });
                          }}
                        >
                          <ExpandMoreIcon />
                        </Button>
                      </div> */}

                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      label="Reminder Title"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="AllergyTitle"
                      onChange={this.changeHandler}
                      value={AllergyTitle}
                    />
                    <TextField
                      label="Next Appointment Date"
                      type="date"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="AllergyNext"
                      onChange={this.changeHandler}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={AllergyNext}
                    />
                  </div>

                  {/* <div className="addNewRecord-popup-wrapper-div">
                   
                  </div> */}

                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      select
                      label="Remind Me"
                      variant="outlined"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="AllergyRemind_me"
                      value={AllergyRemind_me}
                      onChange={this.changeHandler}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="On The Same Day">
                        On The Same Day
                      </MenuItem>
                      <MenuItem value="1 Day Before">1 Day Before</MenuItem>
                      <MenuItem value="2 Days Before">2 Days Before</MenuItem>
                      <MenuItem value="3 Days Before">3 Days Before</MenuItem>
                      <MenuItem value="15 Days Before">15 Days Before</MenuItem>
                      <MenuItem value="1 Month Before">1 Month Before</MenuItem>
                    </TextField>
                    <TextField
                      label="Notes"
                      rowsMin={2}
                      className="addNewRecord-popup-wrapper-div-textarea"
                      name="AllergyReminderNotes"
                      onChange={this.changeHandler}
                      value={AllergyReminderNotes}
                    />
                  </div>

                  {/* <div className="addNewRecord-popup-wrapper-div">
                   
                  </div> */}
                </div>
              ) : (
                ""
              )}

              <div className="addNewRecord-uploadReport-container">
                <label>
                  <AiIcons.AiOutlineUpload /> Report
                </label>

                <MaterialFileUpload
                  onChange={this.handleChange}
                  multiple={false}
                  accept={[
                    ".pdf",
                    ".docx",
                    ".docs",
                    ".png",
                    ".jpg",
                    ".jpeg",
                    ".csv",
                    ".xlsx",
                  ]}
                  maxFileSize={5 * 1024 * 1024}
                />

                {this.state.allergyFiles.map((file, index) => (
                  <span key={index} className="file-container">
                    {file.type === "application/pdf" ? (
                      <AiIcons.AiFillFilePdf className="file-icon" />
                    ) : (
                      <AiIcons.AiFillFile className="file-icon" />
                    )}
                    <span className="file-name">{file.name}</span>
                    <button
                      className="remove-button"
                      onClick={() => this.handleRemove(index)}
                    >
                      <AiIcons.AiOutlineClose className="remove-icon" />
                    </button>
                  </span>
                ))}

                <label style={{ color: "red" }}>{this.state.message}</label>
              </div>
            </div>
          )}

          {value === 3 && (
            <div className="addNewRecord-popup-wrapper">
              <div className="addNewRecord-popup-wrapper-div">
                {/* <label>Medication Name *</label> */}
                <TextField
                  type="text"
                  label="Medication Name"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="Medication_name"
                  value={this.state.Medication_name}
                  required
                  onChange={this.changeHandler}
                />
                <TextField
                  type="text"
                  label="Doctor's Name"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="MedicationDoctor_name"
                  value={this.state.MedicationDoctor_name}
                  onChange={this.changeHandler}
                />
              </div>

              {/* <div className="addNewRecord-popup-wrapper-div">
        
               
              </div> */}

              <div className="addNewRecord-popup-wrapper-div">
                {/* <label>Medication Start Date *</label> */}
                <TextField
                  type="date"
                  label="Medication Start Date"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="Medication_startdate"
                  required
                  value={this.state.Medication_startdate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={this.changeHandler}
                />
                <TextField
                  type="date"
                  label="Medication End Date"
                  variant="outlined"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="Medicalcondition_enddate"
                  // inputProps={{ min: Medication_startdate }}
                  value={this.state.Medicalcondition_enddate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={this.changeHandler}
                />
              </div>

              {/* <div className="addNewRecord-popup-wrapper-div">
                
              </div> */}

              <div className="addNewRecord-popup-wrapper-div">
                {/* <label>Dosage</label> */}
                <TextField
                  type="text"
                  label="Dosage"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="MedicationDosage"
                  onChange={this.changeHandler}
                  value={this.state.MedicationDosage}
                />
                <TextField
                  className="addNewRecord-popup-wrapper-div-textarea"
                  label="Notes"
                  rowsMin={2}
                  name="MedicationNotes"
                  onChange={this.changeHandler}
                  value={this.state.MedicationNotes}
                />
              </div>

              {/* <div className="addNewRecord-popup-wrapper-div">
              </div> */}

              <Button
                onClick={() => {
                  this.setState({
                    medication_reminder: !medication_reminder,
                  });
                }}
              >
                <AddAlertIcon />
                Set Reminder
              </Button>

              {medication_reminder ? (
                <div className="addNewRecord-set-reminder">
                  {/* <div className="">
                        <label>Medication</label>

                        <Button
                          className="p-0 myexp-btn"
                          style={{ marginLeft: "80px" }}
                          onClick={() => {
                            this.setState({
                              medication_reminder: !medication_reminder,
                            });
                          }}
                        >
                          <ExpandMoreIcon />
                        </Button>
                      </div> */}

                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      type="text"
                      label="Reminder Title"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="MedicationTitle"
                      onChange={this.changeHandler}
                      value={this.state.MedicationTitle}
                    />
                    <TextField
                      type="date"
                      label="Next Appointment Date"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="MedicationNext"
                      value={this.state.MedicationNext}
                      onChange={this.changeHandler}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>

                  {/* <div className="addNewRecord-popup-wrapper-div">
                   
                  </div> */}

                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      select
                      label="Remind Me"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="MedicationRemind_me"
                      value={this.state.MedicationRemind_me}
                      onChange={this.changeHandler}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="On The Same Day">
                        On The Same Day
                      </MenuItem>
                      <MenuItem value="1 Day Before">1 Day Before</MenuItem>
                      <MenuItem value="2 Days Before">2 Days Before</MenuItem>
                      <MenuItem value="3 Days Before">3 Days Before</MenuItem>
                      <MenuItem value="15 Days Before">15 Days Before</MenuItem>
                      <MenuItem value="1 Month Before">1 Month Before</MenuItem>
                    </TextField>
                    <TextField
                      type="text"
                      label="Notes"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="MedicationReminderNotes"
                      value={this.state.MedicationReminderNotes}
                      onChange={this.changeHandler}
                    />
                  </div>

                  {/* <div className="addNewRecord-popup-wrapper-div">
                   
                  </div> */}
                </div>
              ) : (
                ""
              )}

              <div className="addNewRecord-uploadReport-container">
                <label>
                  <AiIcons.AiOutlineUpload /> Report
                </label>

                <MaterialFileUpload
                  onChange={this.handleChange}
                  multiple={false}
                  accept={[
                    ".pdf",
                    ".docx",
                    ".docs",
                    ".png",
                    ".jpg",
                    ".jpeg",
                    ".csv",
                    ".xlsx",
                  ]}
                  maxFileSize={5 * 1024 * 1024}
                />

                {this.state.medicationFiles.map((file, index) => (
                  <span
                    key={index}
                    className="file-container"
                    // style={{ maxWidth: `${file.name.length * 10}px` }}
                  >
                    {file.type === "application/pdf" ? (
                      <AiIcons.AiFillFilePdf className="file-icon" />
                    ) : (
                      <AiIcons.AiFillFile className="file-icon" />
                    )}
                    <span className="file-name">{file.name}</span>
                    <button
                      className="remove-button"
                      onClick={() => this.handleRemove(index)}
                    >
                      <AiIcons.AiOutlineClose className="remove-icon" />
                    </button>
                  </span>
                ))}

                <label style={{ color: "red" }}>{this.state.message}</label>
              </div>
            </div>
          )}

          {value === 4 && (
            <div className="addNewRecord-popup-wrapper">
              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  type="text"
                  label="Medical Condition Type"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="Medicalcondition_type"
                  required
                  onChange={this.changeHandler}
                />
                <TextField
                  type="text"
                  label="Doctor's Name"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="MedicalconditionDoctor_name"
                  onChange={this.changeHandler}
                />
              </div>

              {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  type="date"
                  label="Diagnosis Date"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="MedicalconditionDiagnosed_date"
                  required
                  onChange={this.changeHandler}
                  value={this.state.MedicalconditionDiagnosed_date}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  type="text"
                  label="Severity"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="MedicalconditionSeverity"
                  value={this.state.MedicalconditionSeverity}
                  onChange={this.changeHandler}
                />
              </div>

              {/* <div className="addNewRecord-popup-wrapper-div">
               
              </div> */}

              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  className="addNewRecord-popup-wrapper-div-textarea"
                  rowsMin={2}
                  label="Notes"
                  name="MedicalconditionNotes"
                  value={this.state.MedicalconditionNotes}
                  onChange={this.changeHandler}
                />
              </div>

              <Button
                onClick={() => {
                  this.setState({
                    medicalcondition_reminder: !medicalcondition_reminder,
                  });
                }}
              >
                <AddAlertIcon />
                Set Reminder
              </Button>
              {medicalcondition_reminder ? (
                <div className="addNewRecord-set-reminder">
                  {/* <div className="">
                        <label>
                          Medical Condition
                        </label>

                        <Button
                          className="p-0 myexp-btn medicon-exp-btn"
                          style={{ marginLeft: "80px" }}
                          onClick={() => {
                            this.setState({
                              medicalcondition_reminder:
                                !medicalcondition_reminder,
                            });
                          }}
                        >
                          <ExpandMoreIcon />
                        </Button>
                      </div> */}
                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      type="text"
                      label="Reminder Title"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="MedicalconditionTitle"
                      value={this.state.MedicalconditionTitle}
                      onChange={this.changeHandler}
                    />
                    <TextField
                      type="date"
                      label="Next Appointment Date"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="MedicalconditionNext"
                      value={this.state.MedicalconditionNext}
                      onChange={this.changeHandler}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>

                  {/* <div className="addNewRecord-popup-wrapper-div">
                   
                  </div> */}

                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      select
                      label="Remind Me"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="MedicalconditionRemind_me"
                      value={this.state.MedicalconditionRemind_me}
                      onChange={this.changeHandler}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="On The Same Day">
                        On The Same Day
                      </MenuItem>
                      <MenuItem value="1 Day Before">1 Day Before</MenuItem>
                      <MenuItem value="2 Days Before">2 Days Before</MenuItem>
                      <MenuItem value="3 Days Before">3 Days Before</MenuItem>
                      <MenuItem value="15 Days Before">15 Days Before</MenuItem>
                      <MenuItem value="1 Month Before">1 Month Before</MenuItem>
                    </TextField>
                    <TextField
                      // rowsMin={2}
                      label="Notes"
                      className="addNewRecord-popup-wrapper-div-textarea"
                      name="MedicalconditionReminderNotes"
                      value={this.state.MedicalconditionReminderNotes}
                      onChange={this.changeHandler}
                    />
                  </div>

                  {/* <div className="addNewRecord-popup-wrapper-div">
                    
                  </div> */}
                </div>
              ) : (
                ""
              )}

              <div className="addNewRecord-uploadReport-container">
                <span>
                  <AiIcons.AiOutlineUpload /> Report
                </span>

                <MaterialFileUpload
                  onChange={this.handleChange}
                  multiple={false}
                  accept={[
                    ".pdf",
                    ".docx",
                    ".docs",
                    ".png",
                    ".jpg",
                    ".jpeg",
                    ".csv",
                    ".xlsx",
                  ]}
                  maxFileSize={5 * 1024 * 1024}
                />

                {this.state.medicalConditionFiles.map((file, index) => (
                  <span
                    key={index}
                    className="file-container"
                    // style={{ maxWidth: `${file.name.length * 10}px` }}
                  >
                    {file.type === "application/pdf" ? (
                      <AiIcons.AiFillFilePdf className="file-icon" />
                    ) : (
                      <AiIcons.AiFillFile className="file-icon" />
                    )}
                    <span className="file-name">{file.name}</span>
                    <button
                      className="remove-button"
                      onClick={() => this.handleRemove(index)}
                    >
                      <AiIcons.AiOutlineClose className="remove-icon" />
                    </button>
                  </span>
                ))}

                <label style={{ color: "red" }}>{this.state.message}</label>
              </div>
            </div>
          )}

          {value === 5 && (
            <div className="addNewRecord-popup-wrapper">
              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  type="number"
                  label="Weight (kg)"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="Weight"
                  required
                  onChange={this.changeHandler}
                />
                <TextField
                  type="number"
                  label="Height (cm)"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="Height"
                  onChange={this.changeHandler}
                />
              </div>

              {/* <div className="addNewRecord-popup-wrapper-div">
              
              </div> */}

              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  type="date"
                  label="Measurement Date"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="Measurement_date"
                  required
                  onChange={this.changeHandler}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  type="text"
                  label="Measured By"
                  className="addNewRecord-popup-wrapper-div-input"
                  name="Measuredby"
                  onChange={this.changeHandler}
                />
              </div>

              {/* <div className="addNewRecord-popup-wrapper-div">
                
              </div> */}

              <div className="addNewRecord-popup-wrapper-div">
                <TextField
                  // rowsMin={2}
                  label="Notes"
                  className="addNewRecord-popup-wrapper-div-textarea"
                  name="MeasurementNotes"
                  onChange={this.changeHandler}
                />
              </div>

              <Button
                onClick={() => {
                  this.setState({
                    measurement_reminder: !measurement_reminder,
                  });
                }}
              >
                <AddAlertIcon />
                Set Reminder
              </Button>

              {measurement_reminder ? (
                <div className="addNewRecord-set-reminder">
                  {/* <div className="row ml-1 mr-1 mt-3 reminder_title p-1 bg-warning">
                        <p className="ml-2 mb-0 font-weight-bold">
                          Measurement
                        </p>
                        <Button
                          className="p-0 myexp-btn measure-exp-btn"
                          style={{ marginLeft: "80px" }}
                          onClick={() => {
                            this.setState({
                              measurement_reminder: !measurement_reminder,
                            });
                          }}
                        >
                          <ExpandMoreIcon />
                        </Button>
                      </div> */}

                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      type="text"
                      label="Reminder Title"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="MeasurementTitle"
                      onChange={this.changeHandler}
                    />
                    <TextField
                      type="date"
                      label="Next Appointment Date"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="MeasurementNext"
                      onChange={this.changeHandler}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>

                  {/* <div className="addNewRecord-popup-wrapper-div">
                   
                  </div> */}

                  <div className="addNewRecord-popup-wrapper-div">
                    <TextField
                      select
                      label="Remind Me"
                      className="addNewRecord-popup-wrapper-div-input"
                      name="MeasurementRemind_me"
                      value={this.state.MeasurementRemind_me}
                      onChange={this.changeHandler}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="On The Same Day">
                        On The Same Day
                      </MenuItem>
                      <MenuItem value="1 Day Before">1 Day Before</MenuItem>
                      <MenuItem value="2 Days Before">2 Days Before</MenuItem>
                      <MenuItem value="3 Days Before">3 Days Before</MenuItem>
                      <MenuItem value="15 Days Before">15 Days Before</MenuItem>
                      <MenuItem value="1 Month Before">1 Month Before</MenuItem>
                    </TextField>
                    <TextField
                      // rowsMin={2}
                      label="Notes"
                      className="addNewRecord-popup-wrapper-div-textarea"
                      name="MeasurementReminderNotes"
                      onChange={this.changeHandler}
                    />
                  </div>

                  {/* <div className="addNewRecord-popup-wrapper-div">
                    
                  </div> */}
                </div>
              ) : (
                ""
              )}

              <div className="addNewRecord-uploadReport-container">
                <span>
                  <AiIcons.AiOutlineUpload /> Report
                </span>

                <MaterialFileUpload
                  onChange={this.handleChange}
                  multiple={false}
                  accept={[
                    ".pdf",
                    ".docx",
                    ".docs",
                    ".png",
                    ".jpg",
                    ".jpeg",
                    ".csv",
                    ".xlsx",
                  ]}
                  maxFileSize={5 * 1024 * 1024}
                />

                {this.state.measurementFiles.map((file, index) => (
                  <span
                    key={index}
                    className="file-container"
                    // style={{ maxWidth: `${file.name.length * 10}px` }}
                  >
                    {file.type === "application/pdf" ? (
                      <AiIcons.AiFillFilePdf className="file-icon" />
                    ) : (
                      <AiIcons.AiFillFile className="file-icon" />
                    )}
                    <span className="file-name">{file.name}</span>
                    <button
                      className="remove-button"
                      onClick={() => this.handleRemove(index)}
                    >
                      <AiIcons.AiOutlineClose className="remove-icon" />
                    </button>
                  </span>
                ))}

                <label style={{ color: "red" }}>{this.state.message}</label>
              </div>
            </div>
          )}

          <div className="addNewRecord-form-btn-wrapper">
            <button
              className="addNewRecord-form-btn"
              disabled={this.state.message}
              style={{ backgroundColor: "#ffc107", color: "white" }}
            >
              Submit
            </button>

            <button
              className="addNewRecord-form-btn"
              style={{
                color: "black",
              }}
              onClick={this.props.closeDialog}
            >
              Close
            </button>
          </div>
        </form>
      </>
    );
  }
}
