export const APP_ENV = "staging";

const urlLookUp = {
  gateway: {
    local: "http://localhost:3004",
    staging: "https://dashboard.tag8.co.in",
    live: "https://dashboard.tag8.in",
  },
  genie: {
    local: "http://localhost:3020",
    staging: "https://genie.tag8.co.in",
    live: "https://genie.tag8.in",
  },
  dolphin: {
    local: "http://localhost:3074",
    staging: "https://dolphin.tag8.co.in",
    live: "https://dolphin.tag8.in",
  },
  key: {
    local: "http://localhost:3050",
    staging: "https://key.tag8.co.in",
    live: "https://key.tag8.in",
  },
};

export const switchUrls = (domain) => {
  switch (APP_ENV) {
    case "local":
      return urlLookUp[domain][APP_ENV];
    case "staging":
      return urlLookUp[domain][APP_ENV];
    case "live":
      return urlLookUp[domain][APP_ENV];
    default:
      break;
  }
};
